/* App.css */

/* Import der Schriftart 'Roboto' */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* CSS-Variablen für konsistente Farbgebung */
:root {
  --primary-color: #007bff; /* Primärfarbe */
  --secondary-color: #6c757d; /* Sekundärfarbe */
  --background-color: #ffffff; /* Hintergrundfarbe */
  --text-color: #333333; /* Textfarbe */
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: rgba(0,0,0,0,0);
}

/* Chat-Fenster */
.chat-window {
  position: fixed;
  bottom: 0;
  right: 20px;
  width: 350px;
  max-width: 100%;
  height: 520px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  box-shadow: 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
}

/* Schließen-Button */
.end-chat-button {
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 24px;
  margin: 10px;
  cursor: pointer;
  color: #999;
  transition: color 0.2s;
}
.end-chat-button:hover {
  color: #ff0000;
}

/* Nachrichtenliste */
.message-list {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #fff;
}

/* Eingabefeld */
.message-input {
  padding: 10px;
  background-color: #ffff;
}

/* Chat-Icon-Button */
.chat-icon-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--primary-color);
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s;
}

.chat-icon-button img {
  width: 30px;
  height: 30px;
}

.chat-icon-button:hover {
  transform: scale(1.05);
}

/* Animation für eingehende Nachrichten */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Nachrichten-Container */
.message-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  animation: fadeIn 0.3s ease-in-out;
}

.message-left {
  align-items: flex-start;
}

.message-right {
  align-items: flex-end;
}

/* Nachrichten-Kopf mit Icon und Sendername */
.message-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.message-left .message-header {
  flex-direction: row;
}

.message-right .message-header {
  flex-direction: row-reverse;
}

.message-header .message-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.message-header .message-sender {
  font-weight: bold;
  margin-left: 8px;
}

.message-right .message-header .message-sender {
  margin-left: 0;
  margin-right: 8px;
}

.message-right .message-header .message-icon {
  margin-left: 8px;
  margin-right: 0;
}

/* Nachrichtenblasen */
.rce-mbox {
  max-width: 70%;
}

.rce-mbox .rce-mbox-text {
  background-color: #ffffff;
  color: #000;
}

.rce-mbox .rce-mbox-text:hover {
  background-color: #e0e0e0;
}

/* Schriftgröße der Nachrichtentexte */
.message-box-text {
  font-size: 16px;
  color: var(--text-color);
}

/* Typing-Indikator */
.typing-indicator {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.typing-indicator .message-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Typing-Animation */
.typing-animation span {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: var(--primary-color);
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out both;
}

.typing-animation span:nth-child(1) {
  animation-delay: -0.32s;
}

.typing-animation span:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  } 
  40% {
    transform: scale(1);
  }
}

/* Feedback-Formular */
.feedback-form {
  padding: 20px;
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.feedback-form h2 {
  margin-bottom: 10px;
}

.feedback-form p {
  margin-bottom: 20px;
}

.feedback-rating {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.feedback-rating span {
  margin-right: 10px;
}

.feedback-form .MuiTextField-root {
  margin-bottom: 10px;
}

.feedback-form .MuiButton-root {
  margin-top: auto;
}

/* Chat-Fußzeile */
.chat-footer {
  text-align: center;
  padding: 10px;
  font-size: 12px;
  background-color: #f1f1f1;
}

.chat-footer a {
  color: var(--primary-color);
  text-decoration: none;
}

.chat-footer a:hover {
  text-decoration: underline;
}

/* Fehlermeldungen */
.error-report {
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}

.error-report a {
  color: var(--primary-color);
  text-decoration: none;
}

.error-report a:hover {
  text-decoration: underline;
}

/* Datenschutz-Hinweis */
.privacy-notice {
  font-size: 12px;
  margin-top: 10px;
}

.privacy-notice a {
  color: var(--primary-color);
  text-decoration: none;
}

.privacy-notice a:hover {
  text-decoration: underline;
}

/* UserInfoModal innerhalb des Chat-Fensters */
.user-info-modal {
  padding: 20px;
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.user-info-modal h2 {
  margin-bottom: 20px;
}

.user-info-modal form {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.user-info-modal .MuiTextField-root {
  margin-bottom: 10px;
}

/* Responsives Design */
@media (max-width: 600px) {
  .chat-window {
    width: 100%;
    height: 100%;
    border-radius: 0;
    right: 0;
    bottom: 0;
  }

  .end-chat-button {
    font-size: 20px;
    margin: 2px;
  }
}

@media (max-width: 480px) {
  .message-box-text {
    font-size: 14px;
  }
}
